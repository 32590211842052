import * as React from "react";
import { ViewContext } from "../constants/context.ts";
import { slideUp } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Dispatch, SetStateAction, useContext, useState, useEffect } from "react";
function Oracle1() {
  const { showView } = useContext(ViewContext);
  const [verifiedUsers, setVerifiedUsers] = useState<number | string>(0);
  const [premiumFeatures, setPremiumFeatures] = useState<number | string>(0);
  const [positiveFeedback, setPositiveFeedback] = useState<number | string>(0);

  const startCounter = (end: number, duration: number, setState: Dispatch<SetStateAction<number | string>>, suffix: string = '') => {
    let start = 0;
    const increment = end / (duration / 10);

    const counter = setInterval(() => {
      start += increment;
      if (start >= end) {
        clearInterval(counter);
        setState(`${end}${suffix}`);
      } else {
        setState(`${Math.floor(start)}${suffix}`);
      }
    }, 10);

    return () => clearInterval(counter);
  };

  useEffect(() => startCounter(20, 1000, setPremiumFeatures, ' +'), []);
  useEffect(() => startCounter(5000, 2000, setVerifiedUsers, ' +'), []);
  useEffect(() => startCounter(95, 1500, setPositiveFeedback, '%'), []);

  return (
    <motion.div {...slideUp(showView)} className="w-full">
      {/* <div id="oracleAlgo" className="scroll-my-36 max-md:scroll-my-28 relative mx-4 mt-56 max-md:mt-20 mb-56 max-md:mb-20 items-center flex justify-center">
        <div style={{ background: "rgba(255, 205, 175, 0.99)", filter: "blur(67.1500015258789px)" }} className="absolute w-1/2 h-[364px] top-[-10px] rounded-[662px] opacity-[0.05]" />
        <div className="max-w-[1100px] gap-5 flex max-md:flex-col max-md:items-stretch">
          <div className="flex flex-col items-stretch w-[71%] max-md:w-full max-md:ml-0">
            <div className="fuck flex flex-col items-stretch my-auto px-5 max-md:max-w-full max-md:mt-10">
              <div className="text-white text-3xl max-w-[400px]  font-bold max-md:max-w-full max-md:text-2xl">

                <img src="images/log1.webp" alt="" className="man max-w-[350px] mt-[-0.5rem]" />
              </div>
              <div className="text-white text-2xl max-w-[580px] mt-12 max-md:max-w-full max-md:text-lg max-md:mt-10">
                Our world-class trading indicators transforms complicated analysis
                into simplified actionable insights
              </div>

              <div className="fuck mt-10 butt text-neutral-800 cursor-pointer text-center text-xl max-md:text-base font-extrabold whitespace-nowrap bg-stone-50 justify-center items-stretch px-14 py-3.5 max-md:py-3 rounded-[30px] self-start  max-md:px-10">
                <a  href="/OraclePricing">
                  Access For Free
                </a>
              </div>

              <div className="fuck max-md:scale-75 flex items-stretch justify-between gap-5 mt-16 self-start max-md:mt-10">
                <img className="h-14" srcSet="images/o1.png" />
                <img className="h-14" srcSet="images/o2.png" />
              </div>
            </div>
          </div>
          <img className='max-md:mx-auto w-[15vw] more min-w-[200px] max-w-[400px] max-md:mt-12 aspect-[0.5] object-contain object-center ml-12' srcSet="images/log5.webp" />
        </div>
      </div> */}
      <div className="">
        <div className="pt-48 text-center max-md:pb-[150px] pb-[400px] bg-no-repeat bg-cover bg-black w-full bg-center overflow-hidden max-md:mx-auto" style={{ backgroundImage: "url('images/oracle-bg.webp')" }}>
          <div className="max-w-[1091px] max-md:max-w-full flex gap-y-10 flex-col justify-center items-center mx-auto">
            <h1 className="text-6xl text-white font-bold max-md:text-3xl">Clear Signals, Confident Trades</h1>
            <p className="text-3xl text-white max-md:text-xl max-md:px-8">Find confluence in every trade with our powerful trading tools, signals and alerts used by over 5,000+ people globally.</p>
            <a href="https://whop.com/unity-academy/" className="px-[25px] py-[9px] rounded-[12px] flex gap-x-3 items-center border border-[#31BACF] bg-gradient-to-r from-[#072331] to-[#125B68] transform transition-transform duration-300 hover:scale-110"><p className="text-[20px] font-bold text-white">Subscribe</p><img src="images/arrow-up.svg" alt="Arrow Right" /></a>

            <div className="flex max-md:flex-col justify-between items-center mt-24 max-md:mt-10 w-full max-lg:px-8">
              <div className="flex flex-col gap-4 items-center w-full sm:w-auto max-md:mb-10">
                <h3 className="text-5xl text-white font-bold">{premiumFeatures.toLocaleString()}</h3>
                <p className="text-3xl font-bold text-[#929292]">Premium features</p>
              </div>
              <div className="flex flex-col gap-4 items-center w-full sm:w-auto max-md:mb-10">
                <h3 className="text-5xl text-white font-bold">{verifiedUsers.toLocaleString()}</h3>
                <p className="text-3xl font-bold text-[#929292]">Verified users</p>
              </div>
              <div className="flex flex-col gap-4 items-center w-full sm:w-auto max-md:mb-10">
                <h3 className="text-5xl text-white font-bold">{positiveFeedback.toLocaleString()}</h3>
                <p className="text-3xl font-bold text-[#929292]">Positive feedback</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full relative px-8 md:px-24" id="features">
          <div className="text-center mx-auto flex items-center justify-center">
            <script src="https://player.vimeo.com/api/player.js" />
            <iframe
              src="https://player.vimeo.com/video/1034725638?h=0d958bedac&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="OracleAlgo Video"
              className="absolute -top-72 max-md:top-[-160px] w-full h-[50%] max-md:h-[200px] rounded-[12px] rounded-lg"
              allowFullScreen />
          </div>
          <div className="w-full pt-24 pb-96 max-md:pb-10 mt-[300px] max-md:mt-[50px]">
            <div className="w-full text-center">
              <div className="w-full">
                <h3 className="max-md:text-[30px] text-[60px] text-black font-semibold">Trading made effortless</h3>
                <p className="text-[22px] mt-4">Easily find trading opportunities, providing the earliest indications of trending price movement. </p>
                <div className="mt-11 flex flex-wrap justify-between items-stretch gap-6 max-w-[1400px] mx-auto max-md:px-4">
                  <div className="max-md:flex-[100%] flex-[31%] p-4 bg-white rounded-[11px] border-[2px] border-[#DDDDDF] flex flex-col gap-[12px] text-start">
                    <span className="text-[30px] text-[#ADADAD] font-medium">01.</span>
                    <h5 className="text-[22px] font-semibold">Multiple Markets</h5>
                    <p className="text-[#8C8C8C] text-sm">OracleAlgo works with any market with sufficient volume. Including stocks, forex, crypto, indices, and futures.</p>
                  </div>
                  <div className="max-md:flex-[100%] flex-[31%] p-4 bg-white rounded-[11px] border-[2px] border-[#DDDDDF] flex flex-col gap-[12px] text-start">
                    <span className="text-[30px] text-[#ADADAD] font-medium">02.</span>
                    <h5 className="text-[22px] font-semibold">Risk Management</h5>
                    <p className="text-[#8C8C8C] text-sm">OracleAlgo places an emphasis on risk management by identifying optimal confluences for entries, take profits and stoplosses.</p>
                  </div>
                  <div className="max-md:flex-[100%] flex-[31%] p-4 bg-white rounded-[11px] border-[2px] border-[#DDDDDF] flex flex-col gap-[12px] text-start">
                    <span className="text-[30px] text-[#ADADAD] font-medium">03.</span>
                    <h5 className="text-[22px] font-semibold">Strategy Sensitivity</h5>
                    <p className="text-[#8C8C8C] text-sm">You can change the sensitivity of the indicator to be more or less aggressive based on your preferred trading style.</p>
                  </div>
                </div>
                <div className="mt-11 text-center">
                  <a href="https://whop.com/unity-academy/" className="px-[35px] py-[10px] bg-black rounded-[11px] text-xl font-bold text-white inline-block transform transition-transform duration-300 hover:scale-110">Subscribe</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Oracle1;